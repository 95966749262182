import './utils/user-monitoring';
import React from 'react';
import 'reflect-metadata';
import { createRoot, Root } from 'react-dom/client';
import './index.css';
import {
  configureStore,
  AppRoot,
  setAppConfig,
  listenStorageEvents,
  CMicroAppGuest,
  AppStore,
  RootState,
  OnInitCallback,
  OnMountCallback,
  OnUnMountCallback,
  initServiceWorker,
  getAppConfigValue,
} from '@elliemae/pui-app-sdk';
import { Logger } from '@elliemae/pui-diagnostics';
import { logger } from './utils/logger';
import { setHomeRoute, getBasePath, getHomeRoute } from './utils/paths';
import { AppRoutes } from './route';
import appConfig from './app.config.json';
import SessionWatcher from './Auth/sessionWatcher';

listenStorageEvents();
setAppConfig(appConfig);

// if (process.env.NODE_ENV === 'development') {
//   // eslint-disable-next-line global-require, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-var-requires
//   const axe = require('@axe-core/react');
//   // eslint-disable-next-line @typescript-eslint/no-unsafe-call
//   axe(React, ReactDOM, 1000);
// }

// eslint-disable-next-line import/no-mutable-exports
export let app: CMicroAppGuest | null = null;
let store: AppStore | null = null;
let root: Root | null = null;

export const Application = () => {
  if (!app || !store) throw new Error('Application is not initialized');
  const appProps = app.getProps();
  return (
    <AppRoot
      store={store}
      history={appProps.history}
      basename={getHomeRoute()}
      theme={appProps.theme}
      manageSession
    >
      <SessionWatcher />
      <AppRoutes />
    </AppRoot>
  );
};

export const onInit: OnInitCallback = ({ history, homeRoute }) => {
  (logger as Logger).setOptions?.({
    environment: getAppConfigValue('activeEnv'),
    appVersion: window.emui.version,
    instanceId: sessionStorage.getItem('instanceId') || '',
    userId: sessionStorage.getItem('userId') || '',
  });
  logger.info('Application initialized');
  setHomeRoute(homeRoute);
  store = configureStore({} as RootState, history);
};

const onMount: OnMountCallback = ({ containerId }) => {
  const appNode = document.getElementById(containerId);
  root = createRoot(appNode!);
  root.render(<Application />);
};

const onUnmount: OnUnMountCallback = () => {
  if (root) {
    root.unmount();
    root = null;
  }
  return null;
};

app = CMicroAppGuest.getInstance({ logger, onInit, onMount, onUnmount });

initServiceWorker(getBasePath());
