import {
  ChevronItem,
  LeftNavigation as DSLeftNavigation,
  DSLeftNavigationT,
  ITEM_TYPES,
} from '@elliemae/ds-left-navigation';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { Forms } from '@elliemae/ds-icons';
import { useNavigate } from 'react-router-dom';

interface CallbackParams {
  navigate: ReturnType<typeof useNavigate>;
}

type ItemWithCallback = DSLeftNavigationT.GenericItemItemProps & {
  onOpen?: (params: CallbackParams) => void;
  children?: DSLeftNavigationT.GenericItemItemProps[];
};

const rateSheetBodyItems = [
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_TEXT_LABEL,
    dsId: 'application-forms-text-label-1',
    itemOpts: {
      label: 'Pipeline',
    },
    onOpen: (params) => {
      console.log('I am open');
      params.navigate(`/pipeline`);
    },
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_TEXT_LABEL,
    dsId: 'application-forms-text-label-2',
    itemOpts: {
      label: 'Global Configuration',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('I am open');
      params.navigate(`/global-configuration`);
    },
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_TEXT_LABEL,
    dsId: 'application-forms-text-label-3',
    itemOpts: {
      label: 'Investor Configuration',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('I am open');
      params.navigate(`/investor-configuration`);
    },
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_TEXT_LABEL,
    dsId: 'application-forms-text-label-4',
    itemOpts: {
      label: 'Rate Sheet Configuration',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('I am open');
      params.navigate(`/client-configuration`);
    },
  },
] as unknown as DSLeftNavigationT.GenericItemItemProps[];

const navItems: ItemWithCallback[] = [
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_SUBMENU,
    dsId: 'application-submenu-forms-id',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Rate Sheet',
      RightComponent: ({
        ctx: {
          leftNavProps: { openedItem },
        },
      }) => (openedItem ? null : ChevronItem()),
    },
    onOpen: (params) => {
      console.log('pipeline I am open');
      params.navigate(`/pipeline`);
    },
    // children: rateSheetBodyItems,
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_LINK,
    dsId: 'rate_definitions-id',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Rate Definitions',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('I am open');
      params.navigate(`/rate-definitions`);
    },
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_LINK,
    dsId: 'programFlagId',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Program Flags',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('Open program flags');
      params.navigate(`/program-flags`);
    },
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_LINK,
    dsId: 'client-id',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Clients',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('open clients Page');
      params.navigate(`/clients`);
    },
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_LINK,
    dsId: 'clientFlagId',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Client Flags',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('Open client flags');
      params.navigate(`/client-flags`);
    },
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_LINK,
    dsId: 'rateDataId',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Rate Data',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('Open Rate Data');
      params.navigate(`/rate-data`);
    },
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_LINK,
    dsId: 'programsId',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Programs',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('Programs');
      params.navigate(`/programs`);
    },
  },
  {
    type: ITEM_TYPES.LEFT_NAV_ITEM_LINK,
    dsId: 'investorRelationshipsId',
    CollapsedComponent: () => (
      <Forms size="m" color={['brand-primary', '800']} />
    ),
    itemOpts: {
      label: 'Investor Relationships',
      RightComponent: ChevronItem,
    },
    onOpen: (params) => {
      console.log('Open Investor Relationships');
      params.navigate(`/investor-relationships`);
    },
  },
  // Add other navigation items here
];

export const LeftNavigation = () => {
  const [expanded, setExpanded] = useState(true);
  const [openedItem, setOpenedItem] = useState<string | null>(null);
  const navigate = useNavigate();
  const onOpenParams: CallbackParams = {
    navigate,
  };
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [selectedParent, setSelectedParent] = useState<string | null>(null);

  const handleItemClick = (item: DSLeftNavigationT.GenericItemItemProps) => {
    if (!expanded) {
      setExpanded(true);
    } else if (openedItem === item.dsId) {
      setOpenedItem(null);
    } else if (
      item.dsId &&
      item.itemOpts.openable &&
      [
        ...navItems.map((navItem) => navItem.dsId),
        'application-header-id',
      ].includes(item.dsId)
    ) {
      setOpenedItem(item.dsId);
    }
  };
  const onItemClickHandler = useCallback(
    (item: ItemWithCallback) => {
      if (item.type === ITEM_TYPES.LEFT_NAV_ITEM_SUBMENU) {
        setOpenedItem(openedItem === item.dsId ? null : item.dsId ?? null); // Toggles the submenu
      } else if (item.onOpen) {
        item.onOpen(onOpenParams); // Navigate for non-submenu items
      }
    },
    [openedItem, onOpenParams],
  );

  const getBodyItems = useMemo(() => {
    switch (openedItem) {
      case null:
        return navItems;

      case 'application-submenu-forms-id':
        return rateSheetBodyItems;

      default:
        return [];
    }
  }, [openedItem]);

  const getBodyHeaderComponent = () => {
    switch (openedItem) {
      case 'application-header-id':
        return undefined;
      default:
        return navItems.find((item) => item.dsId === openedItem);
    }
  };

  return (
    <DSLeftNavigation
      expanded={expanded}
      openedItem={openedItem}
      BodyHeaderComponent={getBodyHeaderComponent()}
      items={getBodyItems}
      onItemClick={onItemClickHandler}
      selectedItem={selectedItem}
      selectedParent={selectedParent}
      onSelectedChange={setSelectedItem}
      onFooterExpand={() => {
        setExpanded(true);
        //   setOpenedItem(selectedParent);
      }}
      onFooterClose={() => {
        setExpanded(false);
        //   setOpenedItem(null);
        //  setShowHeaderSearch(false);
      }}
    />
  );
};
