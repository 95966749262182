import { Page, useInjectSaga } from '@elliemae/pui-app-sdk';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from '../view/layout';

// import { LoanView } from '../view/loan-view/loadable';
import { onSessionEvent } from '../sideeffect/session';
// import { DataPage1 } from '../view/data-page-1/loadable';
import LoadableClientConfiguration from '../view/client-configuration/loadable';
import LoadablePipelineConfiguration from '../view/pipeline/loadable';
import ClientDefinition from '../view/clients/clientDefinition';
import { Clients } from '../view/clients/loadable';
import LoadableGlobalConfiguration from '../view/global-configuration/loadable';
import LoadableInvestorConfiguration from '../view/investor-configuration/loadable'; // Adjusted path
import LoadableLoginForm from '../view/login/loginform';
import { PricingSearchLayout } from '../view/pricing-search/layout/index';
import LoadableSchemaView from '../view/pricing-search/schema-view/loadable';
import { ProgramFlags } from '../view/program-flags/loadable';
import { RateData } from '../view/rate-data/loadable';
import {
  EditPage,
  RateDefinitions,
  RateDefinitionsEdit,
} from '../view/RateDefinitions/loadable';
import { Programs } from '../view/programs/loadable';
import ProgramDefinition from '../view/programs/programDefinition';
import ProgramVersions from '../view/programs/programVersions';
import ConditionVersions from '../view/programs/conditionVersions';
import GuidelineVersions from '../view/programs/guidelineVersions';
import RatioVersions from '../view/programs/ratioVersions';
import { InvestorRelationships } from '../view/investor-relationships/loadable';
import ClientInvestorRelationship from '../view/investor-relationships/clientInvestorRelationship';
import ClientInvestorRelationshipVersions from '../view/investor-relationships/clientInvestorRelationshipversions';
import { ClientFlags } from '../view/ClientFlags/loadable';

const key = 'session';

export const AppRoutes = () => {
  useInjectSaga({ key, saga: onSessionEvent });
  return (
    <Routes>
      <Route path="/pricing-search" element={<PricingSearchLayout />}>
        <Route
          path="/pricing-search"
          element={<Navigate to="/pricing-search/schema" />}
        />
        <Route
          path="/pricing-search/schema"
          element={
            <Page pageTitle="Pricing Search">
              <LoadableSchemaView />
            </Page>
          }
        ></Route>
      </Route>
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={
            <Page pageTitle="LoginForm">
              <LoadableLoginForm />
            </Page>
          }
        />
        <Route
          path="investor-configuration"
          element={
            <Page pageTitle="Investor Configuration">
              <LoadableInvestorConfiguration />
            </Page>
          }
        />

        <Route
          path="global-configuration"
          element={
            <Page pageTitle="Global Configuration">
              <LoadableGlobalConfiguration />
            </Page>
          }
        />
        <Route
          path="client-configuration"
          element={
            <Page pageTitle="Rate Sheet Configuration">
              <LoadableClientConfiguration />
            </Page>
          }
        />
        <Route
          path="pipeline"
          element={
            <Page pageTitle="Pipeline Configuration">
              <LoadablePipelineConfiguration />
            </Page>
          }
        />
        <Route
          path="rate-definitions"
          element={
            <Page pageTitle="RateDefinitions">
              <RateDefinitions />
            </Page>
          }
        />
        <Route
          path="/clients"
          element={
            <Page pageTitle="clients">
              <Clients />
            </Page>
          }
        />
        <Route
          path="/clients/:id"
          element={
            <Page pageTitle="Client Definition">
              <ClientDefinition />
            </Page>
          }
        />
        <Route
          path="/rate-definitions/:pageAction/:id"
          element={
            <Page pageTitle="Edit Rate Definition">
              <EditPage />
            </Page>
          }
        />
         <Route
          path="client-flags"
          element={
            <Page pageTitle="Client Flags">
              <ClientFlags />
            </Page>
          }
        />
        <Route
          path="/rate-definitions/:pageAction/rate/:lenderId/:rateVersionId/:rateDefinitionId/:action"
          element={
            <Page pageTitle="Rate Definition">
              <RateDefinitionsEdit />
            </Page>
          }
        />
        <Route
          path="program-flags"
          element={
            <Page pageTitle="Program Flags">
              <ProgramFlags />
            </Page>
          }
        />
        <Route
          path="rate-data"
          element={
            <Page pageTitle="Rate Data">
              <RateData />
            </Page>
          }
        />
        <Route
          path="programs"
          element={
            <Page pageTitle="programs">
              <Programs />
            </Page>
          }
        />
        <Route
          path="/programs/:id"
          element={
            <Page pageTitle="Program Definition">
              <ProgramDefinition />
            </Page>
          }
        />
        <Route
          path="/programs/:id/versions"
          element={
            <Page pageTitle="Program Versions">
              <ProgramVersions />
            </Page>
          }
        />
        <Route
          path="/programs/:id/conditions"
          element={
            <Page pageTitle="Condition Versions">
              <ConditionVersions />
            </Page>
          }
        />
        <Route
          path="/programs/:id/guidelines"
          element={
            <Page pageTitle="Guideline Versions">
              <GuidelineVersions />
            </Page>
          }
        />
        <Route
          path="/programs/:id/ratios"
          element={
            <Page pageTitle="Ratio Versions">
              <RatioVersions />
            </Page>
          }
        />
        <Route
          path="investor-relationships"
          element={
            <Page pageTitle="Investor Relationships">
              <InvestorRelationships />
            </Page>
          }
        />
        <Route
          path="/investor-relationships/:clientLenderId"
          element={
            <Page pageTitle="Investor Relationship">
              <ClientInvestorRelationship />
            </Page>
          }
        />
        <Route
          path="/investor-relationships/:clientLenderId/versions"
          element={
            <Page pageTitle="Investor Relationship versions">
              <ClientInvestorRelationshipVersions />
            </Page>
          }
        />
        {/* <Route
          path="client-configuration"
          element={
            <Page pageTitle="Rate Sheet Configuration">
                           <LoadableClientConfiguration />
              
            </Page>
          }
        /> */}

        {/* <Route
          path="admin"
          element={
            <RequireAuth>
              <Page pageTitle="Admin">
                <LoadableLoginForm />
              </Page>
            </RequireAuth>
          }
        /> */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};
