import React from 'react';
import { CommonHeader } from '../common/headerUtil';

const ClientInvestorRelationshipVersions = () => {
  return (
    <>
      <CommonHeader titleText="Client-Investor Relationship Versions" />
      {/* Your component content goes here */}
      <h1>Client Investor Relationship Versions</h1>
    </>
  );
};

export default ClientInvestorRelationshipVersions;