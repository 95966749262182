import { getAuthHTTPClient } from '@elliemae/pui-app-sdk';
import { API_URL } from '../api/config';
const baseUrl = API_URL;

const LEGAL_ENTITY_UUID = '8ea2c0e0-4139-4e90-9539-1f8b975cae87';
export class AuthenticationService {
  client: ReturnType<typeof getAuthHTTPClient>;

  static refreshToken: any;

  constructor() {
    this.client = getAuthHTTPClient();

    // Add a request interceptor to set default headers
    this.client.interceptors.request.use((config) => {
      const accessToken = localStorage.getItem('accessToken'); // Fetch from localStorage
      const legalEntityId = LEGAL_ENTITY_UUID; // Fetch from localStorage

      if (accessToken) {
        config.headers['X-Access-Token'] = accessToken;
      }
      if (legalEntityId) {
        config.headers['X-Legal-Entity'] = legalEntityId;
      }
      return config;
    });
  }

  async login(creds: { emailAddress: string; password: string }) {
    try {
      const response = await this.client.post(
        `${baseUrl}/users/authenticate`,
        creds,
      );
      return response;
    } catch (err: any) {
      if (err?.response && err?.response?.status === 403) {
        console.log('catching MFA error. Continuing');
        return err.response;
      }
      throw err;
    }
  }

  async loginWithMFACode(mfaResponseUuid: string, mfaCode: string) {
    try {
      const response = await this.client.post(
        `${baseUrl}/users/multi-factor/verify/${mfaResponseUuid}?SILENT_ERROR`,
        { code: mfaCode },
      );
      const token = response.data.item.sessionState.accessToken as string;
      const sessionState = response.data.item.sessionState;
      this.client.interceptors.request.use((config) => {
        config.headers['X-Access-Token'] = token;
        return config;
      });
      return [token, sessionState];
    } catch (error) {
      console.error('MFA Verification failed', error);
      throw error;
    }
  }

  async refreshToken() {
    try {
      const accessToken = localStorage.getItem('accessToken'); // Fetch from localStorage
      const legalEntityId = LEGAL_ENTITY_UUID; // Fetch from localStorage

      if (!accessToken || !legalEntityId) {
        throw new Error('Access token or legal entity ID missing.');
      }

      const response = await this.client.post(
        `${baseUrl}/users/authenticate/refresh`,
        null,
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Legal-Entity': legalEntityId,
            'X-Access-Token': accessToken,
          },
        },
      );
      const token = response.data.item.sessionState.accessToken as string;
      const sessionState = response.data.item.sessionState;

      this.client.interceptors.request.use((config) => {
        config.headers['X-Access-Token'] = token;
        return config;
      });
      localStorage.setItem('sessionEnd', sessionState.softExpirationDate);
      return { token, sessionState };
    } catch (error) {
      console.error('Token refresh failed', error);
      throw error;
    }
  }
}
