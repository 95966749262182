// config.js
const API_URLS = {
  //  d1: 'https://api.d1.lcdev.pub.epps.rd.elliemae.io/api/v1',
  d1: 'https://api.d2.lcdev.epps.rd.elliemae.io/api/v1',
  d2: 'https://api.d2.lcdev.epps.rd.elliemae.io/api/v1',
  t1: 'https://api.t1.lcdev.pub.epps.rd.elliemae.io/api/v1',
  i1: 'https://api.i1.lcint.epps.rd.elliemae.io/api/v1',
  q1: 'https://api.q1.lcqa.epps.rd.elliemae.io/api/v1',
};

const LEGAL_ENTITY_UUID = '8ea2c0e0-4139-4e90-9539-1f8b975cae87';
const getApiUrl = () => {
  const { hostname } = window.location;
  if (hostname.includes('d1')) return API_URLS.d1;
  if (hostname.includes('d2')) return API_URLS.d2;
  if (hostname.includes('t1')) return API_URLS.t1;
  if (hostname.includes('i1')) return API_URLS.i1;
  if (hostname.includes('q1')) return API_URLS.q1;
  return API_URLS.d2; // Default to d2 if no match is found
};

export const API_URL = getApiUrl();
export const LEGAL_ENTITY = LEGAL_ENTITY_UUID;
