/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AuthenticationService } from '../../Auth/loginservice';
import HeaderImage from '../images/HeaderImage.png'; // Adjust the path as necessary
import MFAForm from './MFAform';
import './loginform.css';
import { useSessionStore } from '../../store/sessionStore'; // Import the zustand store
import { set } from 'lodash';

type FormData = {
  email: string;
  password: string;
};

const LoginForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [mfaToken, setMfaToken] = useState<string | null>(null);
  const [loginError, setLoginError] = useState<string | null>(null);
  const navigate = useNavigate();
  const service = new AuthenticationService();
  const { setToken } = useSessionStore();

  const onSubmit = async (data: FormData) => {
    try {
      const loginResponse = await service.login({
        emailAddress: data.email,
        password: data.password,
      });
      if (loginResponse.data?.item?.multiFactorResult?.uuid) {
        const mfaResponseUuid = loginResponse.data.item.multiFactorResult.uuid;
        const mfaCode = '111111'; // Constant MFA code
        const [token, sessionState] = await service.loginWithMFACode(
          mfaResponseUuid,
          mfaCode,
        );
        const expirydate = sessionState?.hardExpirationDate;

        const sessionstartDate = sessionState?.createdDate;

        localStorage.setItem('sessionEnd', expirydate);

        const sesExpiry = new Date(sessionState?.hardExpirationDate).getTime();

        // const expiry = sesExpiry.toLocaleString();
        setToken(token, sesExpiry);

        if (token) {
          localStorage.setItem('accessToken', token);
          navigate('/pipeline');
        } else {
          setLoginError('MFA verification failed. Please try again.');
        }
      } else {
        setLoginError('Unexpected error during login. Please try again.');
      }
    } catch (error) {
      setLoginError(
        'Login failed. Please check your credentials and try again.',
      );
    }
  };

  return (
    <div className="login-container">
      <div className="login-image">
        <img src={HeaderImage} alt="Header" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="login-form">
        <h2>Sign In</h2>
        <div className="form-group">
          <label htmlFor="email">Email Address *</label>
          <input id="email" {...register('email', { required: true })} />
          {errors.email && (
            <span className="error-message">This field is required</span>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="password">Password *</label>
          <input
            id="password"
            type="password"
            {...register('password', { required: true })}
          />
          {errors.password && (
            <span className="error-message">This field is required</span>
          )}
        </div>
        <div className="form-actions">
          <button type="submit" className="login-button">
            Login
          </button>
          <button
            type="button"
            className="reset-password"
            onClick={() => console.log('Reset password')}
          >
            Reset Password
          </button>
        </div>
        {loginError && <p className="login-error">{loginError}</p>}
      </form>

      {mfaToken && (
        <MFAForm
          mfaToken={mfaToken}
          onSuccess={() => navigate('/investor-configuration')}
        />
      )}
    </div>
  );
};

export default LoginForm;
