import React from 'react';
import { CommonHeader } from '../common/headerUtil';

const ClientInvestorRelationship = () => {
  return (
    <>
      <CommonHeader titleText="Client-Investor Relationship" />
      {/* Your component content goes here */}
      <h1>Client Investor Relationship</h1>
    </>
  );
};

export default ClientInvestorRelationship;