import React, { useState, useCallback, useEffect, ChangeEvent  } from 'react';
import { DSPageHeaderV2, PageHeaderTitle } from '@elliemae/ds-page-header';
import Grid from '@elliemae/ds-grid';
import {
  DSControlledCheckbox,
  DSControlledRadio,
  DSInputText,
} from '@elliemae/ds-controlled-form';
import { DSFormLayoutBlockItem } from '@elliemae/ds-form-layout-blocks';
import { ClientDefinitionContainer } from './styled';
import { useLocation } from 'react-router-dom';

interface FormData {
  disabled: boolean;
  name: string;
  short: string;
  maxUsers: number | null;
  alternates: string;
  options: string[];
  qualTypes: string;
  nmlsId: string;
  salesForceCID: string;
  adminRights: boolean;
  skin: string;
  customSubmit: string;
  feeDescript: string;
  fees: string;
  encompassUri: string;
  los: string[];
  submitMode: string;
  notes: string;
  cpm: string[];
}

const ClientForm = () => {
  const location = useLocation();
  const client = location.state?.client;

  const [selectedInvestors, setSelectedInvestors] = useState<string[]>([]);
  const [formData, setFormData] = useState<FormData>({
    disabled: true,
    name: '0 Development Client',
    short: '0Dev',
    maxUsers: null,
    alternates: '50',
    options: [],
    qualTypes: '',
    nmlsId: '',
    salesForceCID: '11147566',
    adminRights: false,
    skin: 'Default',
    customSubmit: '',
    feeDescript: '',
    fees: '',
    encompassUri: '',
    los: [],
    submitMode: 'Traditional Submit',
    notes: '',
    cpm: [],
  });

  
  const rows = ['40px'];
  const cols = [1, 1];
  const investors = [
    'No Investor',
    'Googain',
    'ICE Mort Tech',
    'FHAICE Mort Tech',
    'USDAICE Mort Tech',
    'VARate Plus Inc',
  ];

  const [categories, setCategories] = useState([
    { id: 1, name: 'Retail' },
    { id: 2, name: 'TPO' },
    { id: 3, name: 'Wholesale' },
  ]);

  useEffect(() => {
    if (client?.Client) {
      setFormData((prevData) => ({
        ...prevData,
        name: client.Client, // Set name from client.Client prop
      }));
    }
  }, [client?.Client]);

  const toggleInvestor = useCallback((investor: string) => {
    setSelectedInvestors((prevSelected) =>
      prevSelected.includes(investor)
        ? prevSelected.filter((item) => item !== investor)
        : [...prevSelected, investor],
    );
  }, []);

  const handleSave = () => {
    console.log('Form saved:', formData);
    console.log('Form saved:', formData);
    // Add your save functionality here
  };

  const handleCancel = () => {
    console.log('Form changes canceled');
    console.log('Form changes canceled');
    // Optionally reset form or navigate away
  };

  const handleCheckboxChange = (newVal: boolean, e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    const { value } = target; // Ensure target is an HTMLInputElement
  
    setFormData((prevData) => {
      const options = newVal
        ? [...prevData.options, value]
        : prevData.options.filter((opt) => opt !== value);
      return { ...prevData, options };
    });
  };

  const handledisableChange = (
    newVal: boolean,
    e: React.SyntheticEvent<HTMLInputElement | HTMLLabelElement | HTMLDivElement>
  ) => {
    // Type narrowing for the target to ensure it's an HTMLInputElement
    const target = e.target as HTMLInputElement;
  
    if (target) {
      const { name } = target;  // Destructure the 'name' property from target
      setFormData((prevData) => {
        return { ...prevData, [name]: newVal };  // Update the form data with the new value
      });
    } else {
      console.error('e.target is undefined or not an input element');
    }
  };

  // Handle changes for both text inputs and select elements
  const handleValueChange = (name: string, value: string | number) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Update formData dynamically based on the input name
    }));
  };
  // Specific handler for dropdown changes (e.g., skin)
  const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target; // Get name and value from the dropdown event
    handleValueChange(name, value); // Use the common handler for text and number inputs
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  const handleCategoryChange = (categoryId, newName) => {
    // Update the category name in your state
    setCategories((prevCategories) =>
      prevCategories.map((category) =>
        category.id === categoryId ? { ...category, name: newName } : category,
      ),
    );
  };

  // Add a new row
  const handleAddCategory = () => {
    setCategories([...categories, { id: Date.now(), name: 'New Category' }]);
  };

  // Handle category change (in case you want to update it)
  const handleDeleteCategory = (id) => {
    setCategories(categories.filter((category) => category.id !== id));
  };

  // Delete category row
  const handleDeleteRow = (index: number) => {
    const updatedCategories = categories.filter((_, i) => i !== index);
    setCategories(updatedCategories);
  };

  const today = new Date();
  const formattedDate: string = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(today);

  return (
    <ClientDefinitionContainer className="client-definition">
      <Grid style={{ height: '2em' }}>
        <DSPageHeaderV2
          pageTitle={
            <PageHeaderTitle>
              Client Definition{' '}
              <span
                style={{
                  position: 'absolute',
                  fontSize: '15px',
                  left: 'auto',
                  right: 'calc(50% - 500px)',
                }}
              >
                {formattedDate}
              </span>
            </PageHeaderTitle>
          }
        />
      </Grid>
      <Grid style={{ marginTop: '50px' }}>
        <form onSubmit={handleSubmit}>
          <Grid cols={[1, 1, 1]} gutter="x1" rows={rows}>
            <label className="id-field ">ID: 0</label>
            <label className="disabled-field">
              <DSControlledCheckbox
                checked={formData.disabled}
                name="disabled"
                value="Disabled"
                label="Disabled"
                id="disabled"
                onChange={handledisableChange}
              />
            </label>
            <label className="right-align">
              Skin:
              <select
                name="skin"
                value={formData.skin}
                onChange={handleDropdownChange}
                style={{ width: '150px' }}
              >
                <option value="Default">Default</option>
                <option value="Custom">Custom</option>
              </select>
            </label>
          </Grid>
          <Grid cols={cols} gutter="x1" rows={rows}>
            <label htmlFor="name-input">
              Name:
              <DSInputText
                aria-label="Input Text Default Size"
                value={formData.name}
                inputSize="m"
                onValueChange={(value) => handleValueChange('name', value)} // Just pass value directly
                onClear={() => handleValueChange('name', '')} // Clear the name input
              />
            </label>

            <label htmlFor="custom-submit-input" className="right-align">
              Custom Submit:
              <DSInputText
                aria-label="Input Text Default Size"
                value={formData.customSubmit}
                inputSize="m"
              />
            </label>
          </Grid>
          {/* Third Row with Short and Fee Descript */}
          <Grid cols={cols} gutter="x1" rows={rows}>
            <label htmlFor="short-input">
              Short:
              <DSInputText
                aria-label="Input Text Default Size"
                value={formData.short}
                inputSize="m"
              />
            </label>
            <label htmlFor="fee-descript-input" className="right-align">
              Fee Descript:
              <DSInputText
                aria-label="Input Text Default Size"
                value={formData.feeDescript}
                inputSize="m"
              />
            </label>
          </Grid>
          {/* Third Row for Max Users and Fees */}
          <Grid cols={cols} gutter="x1" rows={rows}>
            <label htmlFor="max-user-input">
              Max Users:
              <DSInputText
                aria-label="Input Text Default Size"
                value={formData.maxUsers === null ? '' : formData.maxUsers}
                inputSize="m"
              />
              <span style={{ fontSize: '12px' }}>(blank = unlimited)</span>
            </label>

            <label htmlFor="fees-input" className="right-align">
              Fees $:
              <DSInputText
                aria-label="Input Text Default Size"
                value={formData.fees}
                inputSize="m"
              />
            </label>
          </Grid>
          <Grid cols={2} gutter="x1" rows={rows}>
            <label htmlFor="alternate-input">
              Alternates:
              <DSInputText
                aria-label="Input Text Default Size"
                value={formData.encompassUri}
                inputSize="m"
              />
            </label>

            <label htmlFor="encompas-uri-input" className="right-align">
              Encompass Server URI:
              <DSInputText
                aria-label="Input Text Default Size"
                value={formData.encompassUri}
                inputSize="m"
              />
            </label>
            {/* New Row for Options and LOS */}
          </Grid>
          <Grid cols={2} gutter="x1" rows={['80px']}>
            {/* Left Column: Options Checkboxes */}
            <DSFormLayoutBlockItem
              label="Options:"
              inputID="options_id"
              style={{
                display: 'grid',
                gridTemplateColumns: '20px 1fr',
                gap: '1rem',
              }}
            >
              <Grid className="checkbox-column">
                <label>
                  <DSControlledCheckbox
                    checked={formData.options.includes('Demo/Training Client')}
                    name="demo"
                    value="Demo/Training Client"
                    label="Demo/Training Client"
                    id="demoClient"
                    onChange={handleCheckboxChange}
                  />
                </label>
                <label>
                  <DSControlledCheckbox
                    checked={formData.options.includes(
                      'Apply Client Rules/Holdbacks',
                    )}
                    name="apply"
                    value="Apply Client Rules/Holdbacks"
                    label="Apply Client Rules/Holdbacks"
                    id="applyClient"
                    onChange={handleCheckboxChange}
                  />
                </label>
                <label>
                  <DSControlledCheckbox
                    checked={formData.options.includes(
                      'All User Passwords Never Expire',
                    )}
                    name="user"
                    value="All User Passwords Never Expire"
                    label="All User Passwords Never Expire"
                    id="passwordExpire"
                    onChange={handleCheckboxChange}
                  />
                </label>
                <label>
                  <DSControlledCheckbox
                    checked={formData.options.includes('Bypass CAPTCHA')}
                    name="user"
                    value="Bypass CAPTCHA"
                    label="Bypass CAPTCHA"
                    id="bypassCaptcha"
                    onChange={handleCheckboxChange}
                  />
                </label>
              </Grid>
            </DSFormLayoutBlockItem>

            {/* Right Column: LOS Text Boxes */}
            <Grid className="right-align">
              <DSFormLayoutBlockItem
                label="LOS:"
                inputID="los_id"
                style={{
                  display: 'grid',
                  gridTemplateColumns: '20px 1fr',
                  gap: '2rem',
                  width: '55%',
                }}
              >
                <Grid>
                  <label className="right-align">
                    <select
                      name="los"
                      value={formData.los}
                      onChange={handleDropdownChange}
                      style={{ width: '180px' }}
                    >
                      <option value="Default">none</option>
                      <option value="Custom">encompass</option>
                      <option value="Point">Point</option>
                    </select>
                  </label>
                  <label className="right-align">
                    <select
                      name="los"
                      value={formData.los}
                      onChange={handleDropdownChange}
                      style={{ width: '180px' }}
                    >
                      <option value="Default">one-Click</option>
                      <option value="Custom">Summary-Submit</option>
                    </select>
                  </label>
                </Grid>
              </DSFormLayoutBlockItem>
            </Grid>
          </Grid>
          {/* Other form fields go here */}
          <Grid cols={2} gutter="x1" rows={['60px']}>
            <DSFormLayoutBlockItem
              label="Qual Types:"
              inputID="options_id"
              style={{
                display: 'grid',
                gridTemplateColumns: '20px 1fr',
                gap: '1rem',
              }}
            >
              <Grid className="checkbox-column">
                <label>
                  <DSControlledCheckbox
                    checked={formData.qualTypes.includes('1st Mortgage')}
                    name="mortgae"
                    value="1st Mortgage"
                    label="1st Mortgage"
                    id="1st-Mortgage"
                    onChange={handleCheckboxChange}
                  />
                </label>
                <label>
                  <DSControlledCheckbox
                    checked={formData.qualTypes.includes('2nd Mortgage')}
                    name="mortgae"
                    value="2nd Mortgage"
                    label="2nd Mortgage"
                    id="2nd-Mortgage"
                    onChange={handleCheckboxChange}
                  />
                </label>
              </Grid>
            </DSFormLayoutBlockItem>
            <Grid className="right-align">
              <DSFormLayoutBlockItem
                label="Submit Mode:"
                inputID="options_id"
                style={{
                  display: 'grid',
                  gridTemplateColumns: '20px 1fr',
                  gap: '1rem',
                }}
              >
                <Grid className="checkbox-column">
                  <label>
                    <DSControlledRadio
                      value="{formData.submitMode.includes('Traditional Submit')}"
                      applyAriaDisabled
                      label="Traditional Submit"
                      id="id1"
                    />
                  </label>
                  <label>
                    <DSControlledRadio
                      value="{formData.submitMode.includes('Request Lock')}"
                      applyAriaDisabled
                      label="Request Lock"
                      id="id2"
                    />
                  </label>
                </Grid>
              </DSFormLayoutBlockItem>
            </Grid>
          </Grid>
          <Grid cols={2} gutter="x1" rows={['50px']}>
            <label htmlFor="nmls-id-input">
              NMLS ID:
              <DSInputText
                aria-label="Input Text Default Size"
                value={formData.nmlsId}
                inputSize="m"
              />
            </label>

            <label htmlFor="sales-force-cid-input" className="right-align">
              SalesForce CID:
              <DSInputText
                aria-label="Input Text Default Size"
                value={formData.salesForceCID}
                inputSize="m"
              />
            </label>
          </Grid>
          <Grid
            cols={1}
            gutter="x1"
            rows={['50px']}
            style={{ marginLeft: '75px' }}
          >
            <label>
              <DSControlledCheckbox
                checked={formData.qualTypes.includes('Client has Admin Rights')}
                name="clientHasAdminRights"
                value="1st Mortgage"
                label="Client has Admin Rights"
                id="client-adminRights"
                onChange={handleCheckboxChange}
              />
            </label>
          </Grid>
          <Grid cols={2} gutter="x1" rows={['auto']}>
            <label>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th className="table-header">Category</th>
                    <th className="table-header">Delete</th>
                  </tr>
                </thead>
                <tbody className="table-cell">
                  {categories.map((category) => (
                    <tr className="table-cell" key={category.id}>
                      <td className="table-cell">
                        <input
                          type="text"
                          value={category.name}
                          onChange={(e) =>
                            handleCategoryChange(category.id, e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => handleDeleteCategory(category.id)}
                        >
                          del
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </label>
            <Grid className="right-align">
              <DSFormLayoutBlockItem
                label="CPM:"
                inputID="cpm_id"
                style={{
                  display: 'grid',
                  gridTemplateColumns: '10px 1fr',
                }}
              >
                <Grid className="checkbox-column">
                  <label>
                    <DSControlledCheckbox
                      checked={formData.cpm.includes('Access Virtual Programs')}
                      name="virtual"
                      value="Access Virtual Programs"
                      label="Access Virtual Programs"
                      id="virtual_id"
                      onChange={handleCheckboxChange}
                    />
                  </label>
                  <label>
                    <DSControlledCheckbox
                      checked={formData.cpm.includes('Access Custom Programs')}
                      name="custom"
                      value="Access Custom Programs"
                      label="Access Custom Programs"
                      id="custom_id"
                      onChange={handleCheckboxChange}
                    />
                  </label>
                </Grid>
              </DSFormLayoutBlockItem>
              <Grid>
                <label>
                  <div className="investors-box">
                    <h5>Available Investors</h5>
                    <div className="investor-options">
                      {investors.map((investor, index) => (
                        <div
                          key={index}
                          className={`investor-item ${
                            selectedInvestors.includes(investor)
                              ? 'selected'
                              : ''
                          }`}
                          onClick={() => toggleInvestor(investor)}
                        >
                          {investor}
                        </div>
                      ))}
                    </div>
                  </div>
                </label>
              </Grid>
            </Grid>
          </Grid>
          <Grid cols={1} gutter="x1" rows={['10px']}>
            <label>
              <button
                onClick={handleAddCategory}
                style={{
                  marginTop: '20px',
                  padding: '5px 15px',
                  backgroundColor: '#f5f5f5',
                  color: 'Black',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                Add
              </button>
              <p style={{ marginTop: '10px', fontSize: '12px', color: '#666' }}>
                <strong>Note:</strong> Add{' '}
                <span style={{ fontWeight: 'bold' }}>&lt;,,&gt;</span> & Delete
                actions will lose ALL unsaved changes to this page.
              </p>
            </label>
          </Grid>
          <Grid
            cols={1}
            gutter="x1"
            rows={['50px']}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <label>
              <DSControlledCheckbox
                checked={formData.cpm.includes('External Integration')}
                name="external"
                value="External Integration"
                label="External Integration"
                id="external_id"
                onChange={handleCheckboxChange}
              />
            </label>
          </Grid>
          <Grid
            cols={1}
            gutter="x1"
            rows={['10px']}
            style={{
              display: 'flex',
            }}
          >
            <label>Notes:</label>
            <textarea
              name="notes"
              value={formData.notes}
              style={{
                width: '100%',
                height: '50%',
                resize: 'none',
                padding: '5px',
                boxSizing: 'border-box',
              }}
            />
          </Grid>
          {/* Save and Cancel buttons section */}
          <Grid
            cols={1}
            gutter="x1"
            rows={['auto']}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <button
              type="button"
              onClick={handleSave}
              style={{
                padding: '5px 10px',
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                cursor: 'pointer',
                marginRight: '10px',
                borderRadius: '4px',
              }}
            >
              Save
            </button>

            <button
              type="button"
              onClick={handleCancel}
              style={{
                padding: '5px 10px',
                backgroundColor: '#f5f5f5',
                color: '#333',
                border: '1px solid #ccc',
                cursor: 'pointer',
                borderRadius: '4px',
              }}
            >
              Cancel
            </button>
          </Grid>
        </form>
      </Grid>
    </ClientDefinitionContainer>
  );
};

export default ClientForm;
