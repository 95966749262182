import {
  DSGlobalHeader,
  type DSGlobalHeaderInternalsT,
} from '@elliemae/ds-global-header';
import { Grid } from '@elliemae/ds-grid';
import { ENCDarkIceBlue, ENCIceBlue } from '@elliemae/ds-icons';
import { useCallback, useMemo, useState } from 'react';

const useBreadcrumb = () => {
  const [breadcrumbSelected, setBreadcrumbSelected] = useState('');

  const handleBreadcrumbClick = useCallback(
    (item: string) => () => {
      setBreadcrumbSelected(item);
    },
    [],
  );

  return useMemo(
    () =>
      [
        {
          label: 'ICE PPE - Search Page Customization',
          hasNext: false,
        },
      ] as DSGlobalHeaderInternalsT.BreadcrumbItem[],
    [breadcrumbSelected, handleBreadcrumbClick],
  );
};

export const AppGlobalHeader = () => {
  const breadcrumb = useBreadcrumb();

  return (
    <Grid style={{ minWidth: '570px' }}>
      <DSGlobalHeader
        Logo={ENCIceBlue}
        LogoWithBrand={ENCDarkIceBlue}
        breadcrumb={breadcrumb}
      />
    </Grid>
  );
};

export default AppGlobalHeader;
