import Grid from '@elliemae/ds-grid';
import styled from 'styled-components';

export const ClientDefinitionContainer = styled(Grid)`
  max-width: 2000px;
  height: auto;
  min-height: 900px;
  margin: 1px auto;
  padding: 1px;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: auto;
  form {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  input[type='text'],
  select {
    width: 250px;
    height: 20px;
    margin-left: 10px;
  }

  .checkbox-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .checkbox-label {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }

  .checkbox-label input {
    margin: 0;
  }

  button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .right-align {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    margin-right: 20px;
  }

  button:hover {
    background-color: #0056b3;
  }

  .checkbox-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .checkbox-column {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-left: 50px;
    margin-top: 10px;
    padding: 0;
  }

  .checkbox-column label {
    margin: 0;
  }

  .checkbox-column input[type='checkbox'] {
    margin: 0;
  }

  /* Category Table Styles */
  .category-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  .category-table th,
  .category-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }

  .category-table th {
    background-color: #f4f4f4;
    text-align: center;
    font-weight: bold;
    border: 1px solid #ddd;
  }

  .category-table td {
    text-align: center;
    border: 1px solid #ddd;
  }

  .category-table tr:hover {
    background-color: #f1f1f1;
  }

  button {
    padding: 10px 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: rgb(19, 1, 1);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button:hover {
    background-color: rgb(119, 114, 114);
  }

  button:focus {
    outline: none;
  }

  button:active {
    transform: scale(0.98);
  }

  h3 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .table-header {
    border: 1px solid #ddd;
  }

  .table-cell {
    border: 1px solid #ddd;
    width: 120px;
  }

  /* Container for the entire box */
  .investors-box {
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: Arial, sans-serif;
  }

  /* Container for investor items (flex column layout) */
  .investor-options {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  /* Each investor item box */
  .investor-item {
    padding: 4px 8px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    background-color: #f9f9f9;
    font-size: 12px;
    line-height: 1.2;
    transition: background-color 0.3s, color 0.3s;
  }

  /* Style when an item is selected */
  .investor-item.selected {
    background-color: #007bff;
    color: white;
    font-weight: bold;
  }

  /* Hover effect */
  .investor-item:hover {
    background-color: #e6e6e6;
  }
`;
