import { toast, ToastPosition, ToastType } from '@elliemae/ds-toast';

export enum CustomToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export const triggerToast = (
  type: CustomToastType,
  messageTitle: string,
  messageText: string,
) => {
  toast(
    {
      type,
      messageTitle,
      messageText,
    },
    {
      position: ToastPosition.TOP_RIGHT,
      autoClose: 10000,
    },
  );
};
