/**
 * Asynchronously loads the component for FeaturePage
 */
import { loadable } from '@elliemae/pui-app-sdk';

const LoadablePipelineConfiguration = loadable(
  () =>
    import(
      /* webpackChunkName: "pipeline" */ /* webpackPrefetch: true */ './pipelineComponent'
    ),
);

export default LoadablePipelineConfiguration;
