/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSessionStore } from '../store/sessionStore';
import { CustomToastType, triggerToast } from '../utils/toasthelper';

const SessionWatcher: React.FC = () => {
  const {
    isSessionExpired,
    clearSession,
    token,
    sessionExpiry,
    refreshSession,
  } = useSessionStore();
  const navigate = useNavigate();
  const sessionExpiry1 = localStorage.getItem('sessionEnd');
  const expiryDate = new Date(sessionExpiry1);

  useEffect(() => {
    const interval = setInterval(async () => {
      const now = new Date();
      const timeRemaining = expiryDate.getTime() - now.getTime();

      refreshSession();
      if (timeRemaining <= 900000 && timeRemaining > 840000) {
        triggerToast(
          CustomToastType.WARNING,
          'Warning',
          'Your session will expire in 15 minutes. Please save any unsaved work',
        );
      }
      if (timeRemaining <= 300000 && timeRemaining > 240000) {
        triggerToast(
          CustomToastType.WARNING,
          'Warning',
          'Your session will expire in 5 minutes. Please save any unsaved work',
        );
      }

      if (isSessionExpired()) {
        clearInterval(interval);
        clearSession();
        //   await clearCache(); // Clear cache
        triggerToast(
          CustomToastType.ERROR,
          'Error',
          'Session has expired. Please login again.',
        );

        navigate('/login', { replace: true });

        sessionStorage.clear();
        window.location.reload();
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [isSessionExpired, clearSession, navigate, refreshSession]);

  return null;
};

export default SessionWatcher;
