/* eslint-disable @typescript-eslint/unbound-method */
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useNavigate } from 'react-router-dom';
import { AuthenticationService } from '../Auth/loginservice';

interface SessionState {
  token: string | null;
  isLoggedIn: boolean;
  sessionExpiry: number | null; // Timestamp in milliseconds
  setToken: (token: string, expiry: number) => void;
  clearSession: () => void;
  isSessionExpired: () => boolean;
  refreshSession: () => void;
}

export const useSessionStore = create<SessionState>()(
  persist(
    (set, get) => ({
      token: null,
      isLoggedIn: false,
      sessionExpiry: null,
      setToken: (token, expiry) => {
        set({
          token,
          isLoggedIn: true,
          sessionExpiry: expiry,
        });
      },
      clearSession: () => {
        set({
          token: null,
          isLoggedIn: false,
          sessionExpiry: null,
        });
        localStorage.clear(); // Clear all local storage
        sessionStorage.clear(); // Clear all session storage
      },
      // isSessionExpired: () => {
      //   const { sessionExpiry } = get();
      //   return sessionExpiry !== null && Date.now() > sessionExpiry;
      // },
      isSessionExpired: () => {
        const { sessionExpiry } = get(); // Retrieve sessionExpiry from the store

        if (sessionExpiry === null) return false;

        // Convert sessionExpiry (UTC timestamp) to a local date object
        const localExpiryDate = new Date(sessionExpiry);

        // Get the current system time
        const now = new Date();
        // Compare current time with the local expiry date
        return now > localExpiryDate;
      },
      refreshSession: async () => {
        const { token, sessionExpiry } = get();
        if (!token || !sessionExpiry) return;

        try {
          const authService = new AuthenticationService();
          const { token: newToken, sessionState } =
            await authService.refreshToken();

          const newExpiry = new Date(sessionState.hardExpirationDate).getTime();

          set({
            token: newToken,
            sessionExpiry: newExpiry,
          });

          localStorage.setItem('accessToken', newToken);
          //  localStorage.setItem('sessionEnd', sessionState.hardExpirationDate);
        } catch (error) {
          console.error('Failed to refresh session', error);
          //  clearSession();
        }
      },
    }),
    {
      name: 'session-storage', // Store name for persist
    },
  ),
);
